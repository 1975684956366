import { type TBgColor } from '@telia-front/colors';
import { TranslationsProvider } from '@teliaee/telia-translate';
import { observer, Provider } from 'mobx-react';
import * as React from 'react';
import { Main, ThemeProvider } from 'telia-front-react';

import config from './config';
import { QueryParam } from './Constants';
import Content from './Content';
import RootStore from './stores/RootStore';
import BrandUtil from './utils/BrandUtil';
import ErrorUtil from './utils/ErrorUtil';
import { getApiUrl } from './utils/UrlUtil';
import TeliaLabelProvider from './wrappers/TeliaLabelProvider';

import './css/global';

const resolveAppStyles = () => {
  const brand = (new URLSearchParams(location.search).get(QueryParam.Brand) || 'TELIA').toLowerCase();
  fetch('/brandfree-prepaid/static/manifest.json')
    .then((res) => res.json())
    .then((manifest) => {
      if (document.querySelector(`link[href^="${manifest[`${brand}-styles.css`]}"]`)) {
        return;
      }
      const cssElement = document.createElement('link');
      cssElement.rel = 'stylesheet';
      cssElement.type = 'text/css';
      cssElement.href = `${manifest[`${brand}-styles.css`]}`;
      document.head.appendChild(cssElement);
    })
    .catch((err) => console.error(err));
};

interface IAppState {
  hasError: boolean;
  brand: Brand;
}

export const getIconPath = (name: string): string => {
  const req = require.context('@telia-front/icon/lib/svg/', false, /^\.\/icons.svg$/);
  return req(req.keys()[0]) + name;
};

@observer
class App extends React.Component<unknown, IAppState> {
  private readonly rootStore: RootStore;

  constructor(props: unknown) {
    super(props);
    resolveAppStyles();
    this.rootStore = new RootStore();
    this.state = {
      hasError: false,
      brand: (this.rootStore.routeStore.queryParam(QueryParam.Brand) as Brand) || 'TELIA'
    };
  }

  public componentDidMount(): void {
    this.rootStore.sessionStore.loadSession();
  }

  public componentDidCatch(error: Error): void {
    this.setState({ hasError: true });
    ErrorUtil.registerError(error);
  }

  private getCustomBackgroundColor(): TBgColor | null {
    return this.rootStore.routeStore.queryParam('backgroundColor') as TBgColor | null;
  }

  public render(): JSX.Element {
    const brand = BrandUtil.getBrandType(this.state.brand).toLowerCase();
    const lang = this.rootStore.languageStore.currentLanguage;
    const tier = config.frontendEnvironmentName;
    return (
      <Provider {...this.rootStore}>
        <ThemeProvider
          getIconPath={getIconPath}
          locale={this.rootStore.languageStore.currentLocale}
          // @ts-ignore
          brand={brand}
          theme={brand === 'super' ? 'dark' : undefined}
        >
          <TeliaLabelProvider>
            <TranslationsProvider
              labelsUrl={`${getApiUrl(tier)}/translations/${lang}`}
              defaultApp={'brandfreepp'}
              lang={lang}
              tier={tier}
              includeGlobalTranslations={false}
            >
              <Main background={this.getCustomBackgroundColor()}>
                <Content hasError={this.state.hasError} />
              </Main>
            </TranslationsProvider>
          </TeliaLabelProvider>
        </ThemeProvider>
      </Provider>
    );
  }
}

export default App;
